import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { UntypedFormControl, ValidationErrors, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { AbstractInput } from '../abstract/input';

export const NUMBER_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => InputNumberComponent),
	multi: true
};

export const NUMBER_VALUE_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => InputNumberComponent),
	multi: true,
};

@Component({
	selector: 'app-input-number',
	templateUrl: 'number.component.html',
	providers: [NUMBER_VALUE_ACCESSOR, NUMBER_VALUE_VALIDATOR]
})
export class InputNumberComponent extends AbstractInput<number> {
	@Input()
	placeholder: string = '#';

	@Input()
	min: number | undefined = undefined;

	@Input()
	max: number | undefined = undefined;

	@Input()
	formatThousands: boolean = true;

	@Output() valueChange = new EventEmitter<boolean>();

	onValueChange(newValue: any) {
 
	  this.valueChange.emit(true);  
	}
	
	validate(control: UntypedFormControl): ValidationErrors | null {
		if (this.min && control.value < this.min) {
			return {
				min: this.min
			};
		}
		if (this.max && control.value > this.max) {
			return {
				max: this.max
			};
		}
		return null;
	}
}
